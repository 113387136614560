<template>
    <div class="table-responsive">
        <div class="text-left" v-if="!hideTotal && !isBussy && data.items.length > 0">ilość: {{ data.total }}</div>
        <table class="table table-hover" style="width:100%" v-if="data.items && data.items.length > 0">
            <thead>
            <tr>
                <slot name="table_header">
                    <th v-for="param in tableParams" :class="{'text-end': param.align === 'right', 'text-start': param.align === 'left', 'text-center': param.align === 'center', 'clickable': param.sortable}" @click="param.sortable ? onChangeOrder(param.field) : ''">
                        <span v-if="param.sortable && data.order.by === param.field" :class="{'fas fa-sort-alpha-down': data.order.dir === 'ASC', 'fas fa-sort-alpha-up': data.order.dir === 'DESC'}"></span>
                        {{ param.label }}
                    </th>
                </slot>
            </tr>
            </thead>
            <tbody>
            <slot name="table_data">
                <tr v-for="(item,index) in data.items" @click="() => {if (onClickRow) onClickRow(item)}" :style="onClickRow ? {cursor:'pointer'} : {}" :class="rowClassHandler ? rowClassHandler(item) : ''">
                    <slot name="table_data_row">
                        <td v-for="param in tableParams" :class="{'text-end': param.align === 'right', 'text-start': param.align === 'left', 'text-center': param.align === 'center'}">
                            <span v-if="!param.slot_name && !param.slotComponent && param.field !== '#'" >
                                {{ fieldValue(item, param.field, param.filter)}}
                            </span>
                            <span v-if="param.field === '#'">{{index + 1}}.</span>
                            <slot v-if="param.slot_name" :name="param.slot_name" :item="item" :param="param">

                            </slot>
                            <component v-if="param.slotComponent" :is="param.slotComponent" :item="item"></component>
                        </td>
                    </slot>
                </tr>
            </slot>
            </tbody>
        </table>
        <slot name="table_empty">
        <h3 v-if="!isBussy && data.items.length == 0">Brak danych do wyświetlenia</h3>
        </slot>
    </div>
</template>

<script>
    export default {
        name: "DataTable",
        props: {
            data: Object,
            tableParams: Array,
            onChangeOrder: Function,
            onClickRow: Function,
            isBussy: Boolean,
            hideTotal: Boolean,
            rowClassHandler: Function
        },
        methods: {
            fieldValue(item, field, filter) {
                let ret = field.indexOf('.') > 0 ? field.split('.').reduce( (ret, i) => ret ? ret = ret[i] : '', item) : item[field]
                if (filter) {
                    ret = this.$options.filters[filter](ret);
                }
                return ret;
            },

        }
    }
</script>

<style scoped>

</style>
