<template>
    <page :title="title" :subtitle="subtitle">
        <template v-slot:header-right-buttons>
            <slot name="header-right-buttons">
                <a href="javascript:;"
                   @click="onClickAdd"
                   v-if="onClickAdd && $privileges.loggedUserHasRight(addingPermissionName, PRIVILEGES.PRIVILEGE_KIND_ADD)"
                   class="btn btn-success btn-icon btn-lg"
                >
                    <i class="fa fa-plus fa-lg text-success-900"></i>
                </a>
            </slot>
        </template>
        <template v-slot:header-actions>
            <slot name="header-actions"></slot>
        </template>

        <div v-if="tabs.length > 0" class="card border-0">
            <b-nav class="nav nav-tabs nav-tabs-v2 px-3">
                <b-nav-item v-show="!tab.hidden" v-for="(tab, index) in tabs" :key="tab.slotName" :active="activeTabIndex === index" @click="onClickTab($event, index)">{{tab.title}}</b-nav-item>
            </b-nav>
            <div v-for="(tab, index) in tabs" v-if="activeTabIndex === index" class="border-left border-right border-bottom p-3" :key="tab.slotName">
                <slot :name="'tab-' + tab.slotName" >
                    <h2>Default view for {{ tab.title }} - use slot</h2>
                </slot>
            </div>
        </div>
        <div v-if="tabs.length === 0" class="card border-0">
            <div class="card-header fw-bold">
                <slot name="basic-filters" :onChangeFilters="onChangeFilters" :onClearFilters="onClearFilters" :data="data" :advencedFiltersOpenPriv="advencedFiltersOpenPriv" :onToggleShowFilters="onToggleShowFilters" :searchValue="searchValue">
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-xs-12">
                            <div class="input-group">

                                <span class="input-group-text text-muted border-left-0">
                                    <em class="fa fa-search"></em>
                                </span>

                                <input class="form-control" type="text" placeholder="szukaj" :value="searchValue" @input="onChangeFilters($event)" id="filter_search" name="search">
                            </div>
                        </div>
                        <div class="col-sm-6 text-left">
                            <button type="button" class="btn btn-secondary me-1" @click="onClearFilters"><span class="fas fa-times"/></button>
                            <button v-if="!!$slots['filters']" type="button" class="btn btn-default me-1" @click="onToggleShowFilters()"><span :class="{'fas' : !advencedFiltersOpenPriv, 'fa' : advencedFiltersOpenPriv}" class="fa-filter"/></button>
                        </div>
                    </div>
                </slot>
                <slot name="filters"></slot>
            </div>
            <div class="card-body">
                <slot>
                    <div class="card-body">
                        <slot name="before-data"></slot>
                        <DataTable :data="data" :table-params="tableParams" :onChangeOrder="onChangeOrder" :on-click-row="onClickRow" :is-busy="isBusy" :row-class-handler="rowClassHandler">
                            <template :slot="param.slot_name" v-for="param in tableParams" v-if="param.slot_name" slot-scope="{item}" ><component :is="param.slotComponent" :item="item"></component></template>
                        </DataTable>
                    </div>
                    <mugen-scroll v-if="data.hasMore" :handler="loadMore" :should-handle="isBusy === false" :handleOnMount="false">
                        <app-loading></app-loading>
                    </mugen-scroll>
                </slot>
            </div>
        </div>

    </page>
</template>

<script>
import Page from "@/pages/Common/Page";
import MugenScroll from 'vue-mugen-scroll';
import DataTable from '@/components/Common/DataTable.vue'
import * as SETTINGS from '@/config/Settings'
import * as PRIVILEGES from '@/config/Privileges'

export default {
    name: "ListPage",
    components: {
        Page,
        MugenScroll,
        DataTable
    },
    props: {
        tabs: {
            type: Array,
            required: false,
            default: () => []
        },
        title: String,
        subtitle: String,
        data: Object,
        isBusy: Boolean,
        addingPermissionName: String,
        loadActionName: String,
        tableParams: Array,
        onClickRow: Function,
        onClickAdd: Function,
        filters: Object,
        advencedFiltersOpen: Boolean,
        rowClassHandler: Function
    },
    data() {
        return {
            activeTabIndex: 0,
            filterTimeoutId: null,
            advencedFiltersOpenPriv: false,
            searchValue: '',
            PRIVILEGES
        }
    },
    methods: {
        onClickTab(event, index) {
            this.activeTabIndex = index;
        },
        loadMore() {
            this.$store.dispatch(this.loadActionName, {more: true})
        },
        onChangeOrder(orderBy) {
            let order = {
                by: orderBy,
                dir: this.data.order.by === orderBy ? (this.data.order.dir ===  'ASC' ? 'DESC' : 'ASC') : 'ASC'
            }
            this.$store.dispatch(this.loadActionName, {order: order, filters: this.data.filters})
        },
        onClearFilters() {
            this.searchValue = '';
            this.$store.dispatch(this.loadActionName, {order: this.data.order, filters: {}})
        },
        onChangeFilters (event, bWaitToDebounce = true) {

            let filters = {
                ...this.data.filters,
            }
            if (event) {
                if (Array.isArray(event)) {
                    event.forEach((e) => {
                        filters[e.target.name] = e.target.value
                    })

                } else {
                    filters[event.target.name] = event.target.value
                }
            }
            if (!Array.isArray(event) && event.target.name == 'search') {
                this.searchValue = event.target.value;
            }
            if (this.filterTimeoutId) {
                clearTimeout(this.filterTimeoutId);
            }
            if (bWaitToDebounce) {
                this.filterTimeoutId = setTimeout(() => {
                    this.$store.dispatch(this.loadActionName, {order: this.data.order, filters: filters})
                }, SETTINGS.DEBOUNCE_TIME);
            } else {
                this.$store.dispatch(this.loadActionName, {order: this.data.order, filters: filters})
            }
        },
        onToggleShowFilters() {
            this.advencedFiltersOpenPriv = !this.advencedFiltersOpenPriv;
        }
    },
    mounted() {
        if (this.$route.query.tab) {
            this.activeTabIndex = this.tabs.findIndex( (item) => item.slotName === this.$route.query.tab);
            if (this.activeTabIndex < 0) {
                this.activeTabIndex = 0
            }
        }
    },
    created() {
        const filters = {
            ...this.data.filters,
            ...this.filters
        }

        this.$store.dispatch(this.loadActionName, {filters}).then( () => {
            this.searchValue = this.data.filters.search;
        });
        this.advencedFiltersOpenPriv = this.advencedFiltersOpen

    }
}
</script>

<style scoped>

</style>