<template>
    <ListPage title="Użytkownicy systemowi"
              :data="data"
              :is-busy="isBusy"
              :filters="{}"
              :table-params="[
                  {label: '#', field: 'id', sortable: true, align:'right'},
                  {label: 'Imię Nazwisko', field: 'name', sortable: true, align:'left'},
              ]"
              :load-action-name="TYPES.SYSTEMUSERS_A_LOAD"
              :on-click-add="onClickAdd"
              :adding-permission-name="PRIVILEGES.PRIVILEGE_SYSTEMUSERS"
              :on-click-row="onClickRow"
    >
    </ListPage>
</template>
<script>
import ListPage from "@/pages/Common/ListPage";
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'

import {mapGetters} from 'vuex'
import Vue from "vue";
import TestDialog from "@/pages/Common/TestDialog";
export default {
    name: 'SystemUsersListPage',
    components: {ListPage},
    data() {
        return {
            TYPES,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            data: [TYPES.SYSTEMUSERS_G_GET_ITEMS],
            isBusy: [TYPES.SYSTEMUSERS_G_GET_IS_BUSY]
        })
    },
    methods: {
        onClose() {
            console.log('panel closed');
        },
        onClickRow(row) {
            this.$router.push('/systemusers/' + row.id);
        },
        onClickAdd() {
            this.$router.push('/systemusers/0');
        }
    }
}
</script>