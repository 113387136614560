<template>
<Dialog title="test dialog" :on-ok="onOk" :on-cancel="onCancel" :on-close="onClose">
    <template v-slot:body>
        TO JEST TESTOWY DIALOG<br>
        a to jest tekst wypisany małymi literami
    </template>
</Dialog>
</template>

<script>
import Dialog from "@/pages/Common/Dialog";
export default {
    name: "TestDialog",
    components: {
        Dialog
    },
    methods: {
        onClose() {
            console.log('onClose');
            this.$emit('close');
        },
        onOk() {
            this.$emit('close');
        },
        onCancel() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>