<template>
  <div class="modal d-block position-relative">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <slot name="title">{{ title }}</slot>
        </h4>

        <button type="button" class="btn-close" v-if="onClose" @click="onClickClose"></button>
      </div>
      <div class="modal-body">
        <slot name="body">
        </slot>
      </div>
      <div class="modal-footer">
        <slot name="footer">
          <button type="button" class="btn btn-secondary" v-if="onCancel" @click="onClickCancel">Anuluj</button>
          <button type="button" class="btn btn-primary" v-if="onOk" @click="onClickSave">Ok</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'onClose', 'onCancel', 'onOk'],
  methods: {
    onClickClose() {
      this.onClose()
    },
    onClickCancel() {
      this.onCancel()
    },
    onClickSave() {
      this.onOk()
    }
  }
}
</script>

<style scoped>

</style>
